/**
 * ApolloClient.js
 *
 * @file Setup the Apollo client and export the instance.
 * @author Robin Walter <hello@robinwalter.me>
 */

import { ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client'
import chalk from 'chalk'
import fetch from 'cross-fetch' // In order to make @apollo/client work when Gatsby renders on the server
import logging from 'loglevel'
import { onError } from '@apollo/client/link/error'

// internal imports
import pkg from '../../package.json'

const logger = logging.getLogger('apollo')

//
const httpLink = new HttpLink({
	fetch: fetch,
	uri: process.env.GATSBY_APOLLO_CLIENT_ENDPOINT,
})

//
const errorLink = onError(({ graphQLErrors, networkError, operation, response }) => {
	logger.error(`There was an error in operation ${ operation.operationName }`)

	if ( graphQLErrors )
		graphQLErrors.map( ( { locations, message, path } ) =>
			logger.error(
				`${ chalk.bold.magenta('[GraphQL error]') }: Message: ${ message }, Locations: ${ locations.map( ( location, i ) => `${ i + 1 }. column: ${ location.column }, line: ${ location.line }` ) }, Path: ${ path }`
			)
		)

	if ( networkError )
		logger.error( `${ chalk.bold.gray('[Network error]') }: ${ networkError }` )
})

/** Create a new instance of the apollo client. */
const client = new ApolloClient( {
	cache: new InMemoryCache(),
	link: from([errorLink, httpLink]),
	name: process.env.GATSBY_APOLLO_CLIENT_NAME,
	version: pkg.version,
} )

export default client
