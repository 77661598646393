/**
 * Layout.jsx
 *
 * @file This component creates the overall basic layout of the app.
 * @author Robin Walter <hello@robinwalter.me>
 */

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'
import NProgressBar from '@mui/docs/NProgressBar'
import Paper from '@mui/material/Paper'
import PropTypes from 'prop-types'
import React from 'react'
import Toolbar from '@mui/material/Toolbar'

// internal imports
import { CookieBanner } from '../CookieBanner'
import { FooterBanner } from '../FooterBanner'
import { ResponsiveAppBar } from '../ResponsiveAppBar'
import { ResponsiveAppDrawer } from '../ResponsiveAppDrawer'

/**
 * Create the basic layout.
 *
 * @param {Object} props – The component props.
 * @returns {Node} The styled component.
 */
const Layout = ({ children }) => {

	return (
		<Box sx={{ display: 'flex' }}>
			<NProgressBar />
			<CssBaseline />
			<ResponsiveAppBar />
			<ResponsiveAppDrawer />
			<Box
				component="main"
				sx={(theme) => ({
					flexGrow: 1,
					px: 3,
					[theme.breakpoints.down('sm')]: {
						maxWidth: '100%',
						px: 0,
					},
				})}>
				<Container
					maxWidth="md"
					sx={(theme) => ({
						[theme.breakpoints.down('sm')]: {
							px: 0,
						},
					})}>
					<Paper
						elevation={ 4 }
						square
						sx={{
							display: 'flex',
							flexDirection: 'column',
							flexGrow: 1,
							minHeight: '100vh',
							px: 5,
						}}>
						<Toolbar disableGutters />
						<Box sx={{ flexGrow: 1, pt: 2 }}>
							{ children }
						</Box>
						<FooterBanner />
					</Paper>
				</Container>
			</Box>
			<CookieBanner />
		</Box>
	)
}

/**
 * Define the (expected) prop-types of this component.
 *
 * @typedef {Object} Layout.propTypes
 * @property {Node} children – The children of this component, which should be rendered inside.
 */
Layout.propTypes = {
    children: PropTypes.node.isRequired
}

export default Layout
