/**
 * nprogress.js
 *
 * @file The custom theme configuration for the `nprogress` component.
 * @author Robin Walter <hello@robinwalter.me>
 */

 import { lighten } from '@mui/material/styles'

export default {
	color: lighten('#C41239', 0.1)
}
