/**
 * lightPalette.js
 *
 * @file The custom theme configuration for the light palette.
 * @author Robin Walter <hello@robinwalter.me>
 */

import { blue } from '@mui/material/colors'

const white = '#FFFFFF'
const black = '#1B1B1B'

export default {
	common: {
		black,
		white,
	},
	contrastThreshold: 3,
	primary: {
		contrastText: white,
		main: '#009EE3',
	},
	secondary: {
		contrastText: white,
		main: '#C41239',
	},
	success: {
		contrastText: white,
		main: '#4CAF50', // '#379605',
	},
	info: {
		contrastText: white,
		main: '#03A9F4', // '#808080',
	},
	warning: {
		contrastText: white,
		main: '#FF9800', // '#FFB700',
	},
	error: {
		contrastText: white,
		main: '#EF5350', // '#C51239',
	},
	text: {
		primary: black,
		secondary: '#6C757D',
		link: blue[ 600 ],
	},
	background: {
		default: '#E9ECEF',
		paper: white,
	},
	tonalOffset: 0.2,
	mode: 'light',
}
