/**
 * SettingsDialog.jsx
 *
 * @file This component creates a small settings dialog to customize the website.
 * @author Robin Walter <hello@robinwalter.me>
 */

import _ from 'lodash'
import AddIcon from '@mui/icons-material/Add'
import Autocomplete from '@mui/material/Autocomplete'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import DarkModeIcon from '@mui/icons-material/DarkMode'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { gql, useQuery } from '@apollo/client'
import LightModeIcon from '@mui/icons-material/LightMode'
import logging from 'loglevel'
import MenuItem from '@mui/material/MenuItem'
import React, { useEffect, useState } from 'react'
import RemoveIcon from '@mui/icons-material/Remove'
import SettingsBrightnessIcon from '@mui/icons-material/SettingsBrightness'
import Slider from '@mui/material/Slider'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { useDispatch, useSelector } from 'react-redux'
import validator from 'validator'

// internal imports
import {
	adjustFontSizeSetting,
	changeAppellationSetting,
	changeFirstNameSetting,
	changeMailSetting,
	changeNameSetting,
	changeSchoolSetting,
	selectEventRegistrationSchools,
	selectSettingAppellation,
	selectSettingFirstName,
	selectSettingFontSize,
	selectSettingMail,
	selectSettingName,
	selectSettingSchool,
	selectSettingUIMode,
	selectCookieVisibility,
	setVisibility,
	storeSchools,
	switchUIModeSetting
} from '../../state'

/** Query to get all schools from the server. */
const GET_SCHOOLS = gql`
	query GetSettingsSchools {
		allSchools(
			sort: [
				{ column: "regional_network" order: ASC },
				{ column: "city" order: ASC },
				{ column: "name" order: ASC }
        	]
		) {
        	city
        	firstParticipation
        	id
        	name
        	postalCode
        	regionalNetwork
    	}
	}
`

/**
 * Create the settings dialog.
 *
 * @param {Object} props – The component props.
 * @returns {Node} The styled component.
 */
const SettingsDialog = props => {
	const defaultSchool = {
		city: '',
		firstParticipation: '',
		id: '0',
		key: '0',
		label: 'Bitte wählen Sie eine Schule',
		name: '',
		postalCode: '',
		regionalNetwork: '',
		__typename: 'School'
	}

	const logger = logging
	const loggerGQL = logging.getLogger('graphql')

	// Retrieve the state from the store.
	/** Retrieve the current value for the `appellation` key from the store. */
	const appellationStore = useSelector( selectSettingAppellation )
	/** Retrieve the current value for the `firstName` key from the store. */
	const firstNameStore = useSelector( selectSettingFirstName )
	/** Receive the font size state from the store. */
	const fontSizeStore = useSelector( selectSettingFontSize )
	/** Retrieve the current value for the `mail` key from the store. */
	const mailStore = useSelector( selectSettingMail )
	/** Retrieve the current value for the `name` key from the store. */
	const nameStore = useSelector( selectSettingName )
	/** Retrieve the current value for the `school` key from the store. */
	const schoolStore = useSelector( selectSettingSchool )
	/** Retrieve the fetched `schools` from the store. */
	const schoolsStore = useSelector( selectEventRegistrationSchools )
	/** Receive the font size state from the store. */
	const uiModeStore = useSelector( selectSettingUIMode )
	/** Get the `isVisible` preference from the redux store. */
	const visibility = useSelector( selectCookieVisibility )

	// Add the default school to the schools array, because otherwise the default value
	// can't be found and the website will freeze
	const autoCompleteSchools = [ ...schoolsStore ]
	if ( !_.some(autoCompleteSchools, defaultSchool) ) {
		autoCompleteSchools.unshift( defaultSchool )
	}

	const dispatch = useDispatch()

	// Define the component states.
	/** Define a state holding the chosen appellation. */
	const [ appellation, setAppellation ] = useState( appellationStore )
	/** Define a state holding the first name. */
	const [ firstName, setFirstName ] = useState({
		error: false,
		value: firstNameStore,
		verified: false
	})
	/** Define a state holding the customized font size. */
	const [ fontSize, setFontSize ] = useState( fontSizeStore )
	/** Define a state holding the mail address. */
	const [ mail, setMail ] = useState({
		error: false,
		value: mailStore,
		verified: false
	})
	/** Define a state holding the last name. */
	const [ name, setName ] = useState({
		error: false,
		value: nameStore,
		verified: false
	})
	/** Define a state holding the school itself. */
	const [ school, setSchool ] = useState({
		error: false,
		value: schoolStore,
		verified: false
	})
	/** Define a state holding the customized ui mode. */
	const [ uiMode, setUIMode ] = useState( uiModeStore )

	/** Fetch all events and schools from the GraphQL Server. */
	const { loading } = useQuery( GET_SCHOOLS, {
		displayName: 'GetSettingsSchoolsQuery',
		onCompleted: data => {
			if ( data && data.allSchools ) {
				loggerGQL.info('Storing the schools in the redux store.')
				dispatch(storeSchools(
					data.allSchools.map( school => ( {
						...school,
						key: school.id,
						label: `${ school.name } (${ school.city })`
					} ) )
				))
			}
		},
		onError: error => {
			loggerGQL.error('There was an error when trying to retrieve the schools from the server.', error)
		},
		ssr: false
	} )

	/**
	 * Callback to handle a changed schools.
	 *
	 * @param {Object} event The triggered event object.
	 * @param {Object} newValue The new value for the `school`.
	 * @param {string} reason A reason why this callback was invoked.
	 */
	 const handleSchoolChanged = ( event, newValue, reason ) => {
		let newSchool = defaultSchool

		logger.debug(reason)
		logger.debug(newValue)
		switch ( reason ) {
			case 'selectOption':
				newSchool = newValue
				break
		}

		setSchool( {
			...school,
			value: newSchool
		} )
	}

	/**
	 * Check if the email isn't empty and an actual e-mail address.
	 */
	 const validateEmail = () => {
		if ( !validator.isEmail( mail.value ) || validator.isEmpty( mail.value ) )
			setMail( {
				...mail,
				error: true,
				verified: false
			} )

		else
			setMail( {
				...mail,
				error: false,
				verified: true
			} )
	}

	/**
	 * Check if the names aren't empty and doesn't include any numbers.
	 *
	 * @param {Object} e The triggered event object.
	 */
	const validateName = e => {
		const nameToValidate = e.target.name === 'firstName' ? firstName.value : name.value

		if ( !validator.matches( nameToValidate, /^([^0-9]*)$/ ) || validator.isEmpty( nameToValidate ) ) e.target.name === 'firstName' ?
			setFirstName( {
				error: true,
				value: nameToValidate,
				verified: false
			} )
		:
			setName( {
				error: true,
				value: nameToValidate,
				verified: false
			} )

		else e.target.name === 'firstName' ?
			setFirstName( {
				error: false,
				value: nameToValidate,
				verified: true
			} )
		:
			setName( {
				error: false,
				value: nameToValidate,
				verified: true
			} )
	}

	/**
	 *  Check if the value for the `school` key isn't empty and in the `schools` array.
	 */
	 const validateSchool = () => {
		if (
			!validator.isIn( school.value.name, schoolsStore.map( school => school.name ) )
			|| !_.some( schoolsStore, school.value )
			|| _.isEqual( school.value, defaultSchool )
		)
			setSchool( {
				...school,
				error: true,
				verified: false
			} )

		else
			setSchool( {
				...school,
				error: false,
				verified: true
			} )
	}

	useEffect(() => {
		if (firstName.verified)
			dispatch(changeFirstNameSetting(firstName.value))
		if (mail.verified)
			dispatch(changeMailSetting(mail.value))
		if (name.verified)
			dispatch(changeNameSetting(name.value))
		if (school.verified)
			dispatch(changeSchoolSetting(school.value))
	}, [ firstName.verified, mail.verified, name.verified, school.verified ])

	return (
		<Dialog
			fullWidth
			keepMounted
			maxWidth="md"
			{ ...props }>
			<DialogTitle>Einstellungen</DialogTitle>
			<DialogContent>
				<DialogContentText paragraph variant="caption">
					Im Folgenden haben Sie die Möglichkeit, einige Personalisierungen unserer Website vorzunehmen.
					Unter anderem haben Sie als Schulkoordinator die Möglichkeit, Ihre eigenen Daten zu speichern, um diese
					nicht bei jeder neuen Anmeldung in unseren Anmeldeformularen eingeben zu müssen. Wir möchten darauf hinweisen,
					dass alle folgenden Einstellungen und Angaben ausschließlich lokal auf Ihrem Gerät gespeichert werden.
					Um sicherzustellen, dass Ihre Einstellungen auch beim nächsten Besuch unserer Website erhalten bleiben,
					aktivieren Sie die Option "Präferenzen" in unseren
					<Button
						color="primary"
						onClick={ () => {
							if ( !visibility ) dispatch( setVisibility( true ) )
						} }
						size="small"
						variant="text">
						Cookie-Einstellungen
					</Button>.
				</DialogContentText>
				<DialogContentText gutterBottom variant="subtitle1">UI Modus</DialogContentText>
				<Stack alignItems="center" direction="row" justifyContent="center" spacing={ 2 } sx={{ mb: 2 }}>
					<ToggleButtonGroup
						color="primary"
						exclusive
						onChange={(event, newMode) => {
							setUIMode(newMode)
							dispatch(switchUIModeSetting({ mode: newMode }))
						}}
						size="small"
						sx={(theme) => ({
							[theme.breakpoints.down('sm')]: {
								px: 2,
							},
						})}
						value={ uiMode }>
						<ToggleButton value="light"><LightModeIcon sx={{ mr: 1 }} /> Hell</ToggleButton>
						<ToggleButton value="system"><SettingsBrightnessIcon sx={{ mr: 1 }} /> System</ToggleButton>
						<ToggleButton value="dark"><DarkModeIcon sx={{ mr: 1 }} /> Dunkel</ToggleButton>
					</ToggleButtonGroup>
				</Stack>
				<DialogContentText gutterBottom variant="subtitle1">Textgröße</DialogContentText>
				<Stack alignItems="center" direction="row" spacing={ 2 } sx={{ mb: 2 }}>
					<RemoveIcon />
					<Slider
						aria-label="Schriftgröße"
						max={ 20 }
						min={ 12 }
						onChange={(event, newSize) => {
							setFontSize(newSize)
							dispatch(adjustFontSizeSetting({ size: newSize }))
						}}
						size="small"
						step={ 1 }
						value={ fontSize } />
					<AddIcon />
				</Stack>
				<DialogContentText gutterBottom variant="subtitle1">Angaben zu Ihrer Person:</DialogContentText>
				<Container maxWidth="sm">
					<TextField
						fullWidth
						id="registration-appellation"
						label="Anrede"
						name="appellation"
						onChange={ e => {
							setAppellation( e.target.value )
							dispatch(changeAppellationSetting( e.target.value ))
						} }
						select
						value={ appellation }>
						<MenuItem value=""><em>Keine Angabe</em></MenuItem>
						<MenuItem value="FRAU">Frau</MenuItem>
						<MenuItem value="HERR">Herr</MenuItem>
						<MenuItem value="FRAU_DR">Frau Dr.</MenuItem>
						<MenuItem value="HERR_DR">Herr Dr.</MenuItem>
						<MenuItem value="FRAU_PROF_DR">Frau Prof. Dr.</MenuItem>
						<MenuItem value="HERR_PROF_DR">Herr Prof. Dr.</MenuItem>
					</TextField>
					<TextField
						error={ firstName.error }
						fullWidth
						helperText={ firstName.error ? 'Bitte geben Sie einen Vornamen ein!' : null }
						id="settings-first-name"
						label="Vorname"
						name="firstName"
						onBlur={ validateName }
						onChange={ e => {
							setFirstName( {
								...firstName,
								value: e.target.value
							} )
						} }
						required
						type="text"
						value={ firstName.value } />
					<TextField
						error={ name.error }
						fullWidth
						helperText={ name.error ? 'Bitte geben Sie einen Namen ein!' : null }
						id="settings-name"
						label="Name"
						name="name"
						onBlur={ validateName }
						onChange={ e => {
							setName( {
								...name,
								value: e.target.value
							} )
						} }
						required
						type="text"
						value={ name.value } />
					<TextField
						error={ mail.error }
						fullWidth
						helperText={ mail.error ? 'Bitte geben Sie eine gültige E-Mail Adresse ein!' : null }
						id="settings-mail"
						label="E-Mail Adresse"
						name="mail"
						onBlur={ validateEmail }
						onChange={ e => {
							setMail( {
								...mail,
								value: e.target.value
							} )
						} }
						required
						type="email"
						value={ mail.value } />
					<Autocomplete
						autoComplete={ false }
						clearOnBlur
						defaultValue={ defaultSchool }
						fullWidth
						getOptionLabel={ option => option.label }
						isOptionEqualToValue={ (option, value) => {
							return _.isEqual( option, value )
						} }
						groupBy={ option => option.regionalNetwork }
						handleHomeEndKeys
						id="settings-school"
						name="school"
						onChange={ handleSchoolChanged }
						options={ autoCompleteSchools }
						renderInput={(params) => (
							<TextField
								error={ school.error }
								helperText={ school.error ? 'Bitte geben Sie einen gültigen Schulnamen ein!' : null }
								label="Schulname"
								onBlur={ validateSchool }
								required
								{ ...params } />
						)}
						selectOnFocus
						value={ school.value } />
				</Container>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => {
					props.onClose()
				}}>
					Schließen
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default SettingsDialog
