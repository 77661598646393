/**
 * ResponsiveAppBar.jsx
 *
 * @file This component creates a responsive app bar.
 * @author Robin Walter <hello@robinwalter.me>
 */

import AppBar from '@mui/material/AppBar'
import Container from '@mui/material/Container'
import Hidden from '@mui/material/Hidden'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import React from 'react'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { useDispatch } from 'react-redux'
import { useLocation } from '@reach/router'

// internal imports
import { ButtonLink } from '../ButtonLink'
import routes from '../../data/routes.json'
import { toggleAppDrawer } from '../../state'

/**
 * Create a responsive app bar with navigation.
 *
 * @param {Object} props – The component props
 * @returns {Node} The styled & configured app bar.
 */
const ResponsiveAppBar = () => {

	const dispatch = useDispatch()

	const location = useLocation()

	let title = 'Jugend debattiert NRW'

	routes.forEach( item => {
		if ( item.path === location.pathname ) title = item.title

		if ( item.subroutes ) {
			item.subroutes.forEach( item => {
				if ( item.path === location.pathname ) title = item.title

				if ( item.subroutes ) {
					item.subroutes.forEach( item => {
						if ( item.path === location.pathname ) title = item.title
					} )
				}
			} )
		}
	} )

	return (
        <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
			<Toolbar>
				<Hidden mdUp>
					<IconButton
						aria-label="Öffne Seitennavigation"
						color="inherit"
						edge="start"
						onClick={ () => { dispatch( toggleAppDrawer() ) } }
						size="large"
						sx={{ mr: 2 }}>
						<MenuIcon />
					</IconButton>
				</Hidden>

				<Typography
					component="div"
					noWrap
					sx={{
						display: { xs: 'flex', md: 'block' },
						flexGrow: 1,
						ml: 3,
					}}
					variant="h6">
					{ title }
				</Typography>

				<Hidden mdDown>
					{ routes.reduce( ( result, route ) => {
						if ( route.appBarMenu )
							result.push( route )

						return result
					}, [] ).map( ( { path, title }, i ) => (
						<ButtonLink
							// Check if the path of the current route matches the pathname of the current
							// location, as a workaround of `getProps`. This would override all other `className`s.
							color="inherit"
							key={ i }
							sx={{ textTransform: path === location.pathname ? 'uppercase' : 'none' }}
							to={ path }>
							{ title }
						</ButtonLink>
					) ) }
				</Hidden>
			</Toolbar>
		</AppBar>
    )
}

export default ResponsiveAppBar
