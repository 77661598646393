/**
 * cookieSlice.js
 *
 * @file Define a reducer for the cookie feature.
 * @author Robin Walter <hello@robinwalter.me>
 */

import { createSlice } from '@reduxjs/toolkit'
import isIn from 'validator/es/lib/isIn'

/** Define and export the initial or default cookie state. */
export const cookieInitialState = {
	essential: true,
	preferences: false,
	saved: false,
	social: false,
	stats: false
}

/** Create and export the reducer slice for the `cookie` key. */
export const cookieSlice = createSlice( {
	initialState: {
		...cookieInitialState,
		allowedSocial: {
			Facebook: false,
			Twitter: false,
			YouTube: false
		},
		isVisible: false
	},
	name: 'cookie',
	reducers: {
		/**
		 * Change the permission of a social media.
		 *
		 * @param {Object} state The current state.
		 * @param {Object} action The action with the new state.
		 * @returns {Object} The "mutated" state or fails.
		 */
		changePermissionSocial: ( state, action ) => {
			if ( typeof action.payload.social === 'undefined' || typeof action.payload.value === 'undefined' || typeof action.payload.value !== 'boolean' ) return
			else if ( !isIn( action.payload.social, [ 'Facebook', 'Twitter', 'YouTube' ] ) ) return
			state.allowedSocial[ action.payload.social ] = action.payload.value
		},
		/**
		 * Save the new state.
		 *
		 * @param {Object} state The current state.
		 * @param {Object} action The action with the new state.
		 * @returns {Object} The "mutated" state.
		 */
		save: ( state, action ) => ( {
			...state,
			...action.payload
		} ),
		/**
		 * Set the visibility of the cookie-banner.
		 *
		 * @param {Object} state The current state.
		 * @param {Object} action The action with the new state.
		 * @returns {Object} The "mutated" state.
		 */
		setVisibility: ( state, action ) => {
			state.isVisible = action.payload
		}
	}
} )

/** Export the created action functions. */
export const { changePermissionSocial, save, setVisibility } = cookieSlice.actions

/** Export the selector for the `allowedSocial` key. */
export const selectCookieAllowedSocial = state => state.cookie.allowedSocial
/** Export the selector for the `essential` key. */
export const selectCookieEssential = state => state.cookie.essential
/** Export the selector for the `preferences` key. */
export const selectCookiePreferences = state => state.cookie.preferences
/** Export the selector for the `saved` key. */
export const selectCookieSaved = state => state.cookie.saved
/** Export the selector for the `social` key. */
export const selectCookieSocial = state => state.cookie.social
/** Export the selector for the `stats` key. */
export const selectCookieStats = state => state.cookie.stats
/** Export the selector for the `isVisible` key. */
export const selectCookieVisibility = state => state.cookie.isVisible

/** Export the created reducer function. */
export default cookieSlice.reducer
