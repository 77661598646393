/**
 * typography.js
 *
 * @file The custom theme configuration for the typography.
 * @author Robin Walter <hello@robinwalter.me>
 */

export default {
	body1: {
		fontWeight: 600
	},
	button: {
		fontWeight: 600
	},
	fontFamily: [
		'Nunito',
		'Verdana',
		'sans-serif'
	].join( ', ' ),
	fontSize: 16,
	fontWeightLight: 300,
	fontWeightRegular: 400,
	fontWeightMedium: 600,
	subtitle1: {
		fontWeight: 600
	},
	h6: {
		fontWeight: 600
	}
}
