/**
 * ResponsiveAppDrawer.jsx
 *
 * @file This component creates a responsive navigation bar drawer.
 * @author Robin Walter <hello@robinwalter.me>
 */

import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import { graphql, useStaticQuery } from 'gatsby'
import Hidden from '@mui/material/Hidden'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import React, { useMemo, useState } from 'react'
import SettingsIcon from '@mui/icons-material/Settings'
import { StaticImage } from 'gatsby-plugin-image'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { useDispatch, useSelector } from 'react-redux'

// internal imports
import AppDrawerNavItem from './AppDrawerNavItem'
import { Link } from '../Link'
import staticRoutes from '../../data/routes.json'
import { selectAppDrawer, toggleAppDrawer } from '../../state'
import { SettingsDialog } from '../SettingsDialog'

const iOS = ( typeof window !== 'undefined' ) && /iPad|iPhone|iPod/.test( navigator.userAgent )

// Define importable drawer width
export const drawerWidth = 240

/**
 * Render the navigation list items.
 *
 * @param {Array<Object>} routes An array of objects with the routes.
 * @returns {Node} The rendered list.
 */
const renderAppDrawerNavItems = routes => {

	return routes.map( ( { path, subroutes, title } ) =>
		<AppDrawerNavItem
			key={ path }
			path={ path }
			subroutes={ subroutes }
			title={ title } />
	)

}

/**
 * Create a responsive navigation bar drawer.
 *
 * @returns {Node} The styled & configured app drawer.
 */
const ResponsiveAppDrawer = () => {

	/** Define a state holding the open state of the settings dialog. */
	const [ openSettings, setOpenSettings ] = useState(false)
	/** Store the navigation items in a state */
	const [ routes, setRoutes ] = useState( staticRoutes )

	const data = useStaticQuery( graphql`
		query SchulungenNav {
			allEventType {
				nodes {
					description
					id
					type
				}
			}
		}
	` )

	useMemo( () => {
		let r = routes

		data.allEventType.nodes.forEach( eventType => {

			let newRoute = {
				appBarMenu: false,
				id: eventType.id,
				path: `/schulungen/${ eventType.type }/`,
				title: eventType.description,
				subroutes: [
					{
						appBarMenu: false,
						path: `/schulungen/${ eventType.type }/anmeldung/`,
						title: `Anmeldung für ${ eventType.description }`
					}
				]
			}

			if ( !r[ 3 ].subroutes.some( sr => sr.id === eventType.id ) ) {

				r[ 3 ].subroutes.push( newRoute )

			}
			else {

				let i = r[ 3 ].subroutes.findIndex( sr => sr.id === eventType.id )
				r[ 3 ].subroutes[ i ] = newRoute

			}

		} )

		setRoutes( r )
	}, [ data ] )

	/** Receive the app drawer state from the store. */
	const open = useSelector( selectAppDrawer )

	const dispatch = useDispatch()

	/** Render the actual drawer. Since this "component" is used twice, it is stored inside a constant. */
	const drawer = (
		<Box sx={{
			alignItems: 'flex-start',
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
			justifyContent: 'center',
			maxWidth: drawerWidth,
			p: 0.5,
		}}>
			<Toolbar disableGutters />
			<Link to={ routes[ 0 ].path }>
				<StaticImage
					alt="Jugend debattiert Logo"
					height={ 183 }
					layout="constrained"
					loading="eager"
					placeholder="blurred"
					quality={ 100 }
					src="../../assets/images/Jugend_debattiert_Logo_2019_RGB.jpg"
					width={ 225 } />
			</Link>
			<Divider sx={{ width: '100%' }} />
			<Typography color="secondary" noWrap sx={{ mt: 1, pl: 1.5 }} variant="body1">Landesportal</Typography>
			<Typography color="textPrimary" noWrap sx={{ mb: 1, pl: 1.5 }} variant="subtitle1">Nordrhein-Westfalen</Typography>
			<Divider sx={{ width: '100%' }} />
			<List component="nav" sx={{ flexGrow: 1 }}>
				{ renderAppDrawerNavItems( routes ) }
			</List>
			<IconButton onClick={() => { setOpenSettings(true) }}>
				<SettingsIcon />
			</IconButton>
		</Box>
	)

	return (
        <Box component="nav">
			<Hidden mdUp>
				<SwipeableDrawer
					anchor="left"
					disableBackdropTransition={ !iOS }
					disableDiscovery={ iOS }
					ModalProps={{
						keepMounted: true,
					}}
					onClose={ () => { dispatch( toggleAppDrawer() ) } }
					onOpen={ () => { dispatch( toggleAppDrawer() ) } }
					open={ open }
					variant="temporary">
					{ drawer }
				</SwipeableDrawer>
			</Hidden>
			<Hidden mdDown>
				<Drawer
					sx={{
						flexShrink: 0,
						width: drawerWidth,
						[`& .MuiDrawer-paper`]: {
							boxSizing: 'border-box',
							width: drawerWidth,
						},
					}}
					variant="permanent">
					{ drawer }
				</Drawer>
			</Hidden>
			<SettingsDialog
				onClose={() => {
					setOpenSettings(false)
				}}
				open={ openSettings } />
		</Box>
    )
}

export default ResponsiveAppDrawer
