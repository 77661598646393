/**
 * nprogress.js
 *
 * @file The custom theme configuration for the `nprogress` component.
 * @author Robin Walter <hello@robinwalter.me>
 */

export default {
	color: '#C41239'
}
