/**
 * useEventsFilter.js
 *
 * @file This custom hook can be used to filter the events.
 * @author Robin Walter <hello@robinwalter.me>
 */

import { useState } from 'react'

const useEventsFilter = () => {
	const [filters, _updateFilter] = useState({
		fee: false,
		registrationPeriod: undefined,
		regionalNetwork: undefined,
		startAfter: undefined,
		startBefore: undefined,
		type: undefined,
	})

	const updateFilter = (filterType, value) => {
		_updateFilter({
			[filterType]: value,
		})
	}

	return {
		models: { filters },
		operations: { updateFilter },
	}
}

export default useEventsFilter
