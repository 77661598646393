/**
 * appDrawerSlice.js
 *
 * @file Define a reducer for the app drawer feature.
 * @author Robin Walter <hello@robinwalter.me>
 */

import { createSlice } from '@reduxjs/toolkit'

/** Create and export the slice for the app drawer. */
export const appDrawerSlice = createSlice( {
	initialState: {
		open: false
	},
	name: 'appDrawer',
	reducers: {
		/**
		 * Toggle the current state of the app drawer.
		 *
		 * @param {Object} state The current state of the app drawer.
		 * @returns {Object} The new state of the app drawer.
		 */
		toggle: state => {
			state.open = !state.open
		}
	}
} )

/** Export the action to toggle the app drawer state. */
export const { toggle } = appDrawerSlice.actions

/** Export the selector to receive the current state of the app drawer. */
export const selectAppDrawer = state => state.appDrawer.open

export default appDrawerSlice.reducer
