/**
 * Layout.jsx
 *
 * @file This component creates the overall basic layout of the app.
 * @author Robin Walter <hello@robinwalter.me>
 */

import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import { StaticImage } from 'gatsby-plugin-image'
import Hidden from '@mui/material/Hidden'
import React from 'react'

/**
 * Create a component which holds the footer banner and can easily be integrated
 * in the main `Layout` wrapper component.
 *
 * @returns {Node} The styled component.
 */
const FooterBanner = () => {

	return (
		<Box component="footer" sx={{ flexGrow: 0, py: 2 }}>
			<Divider />
			<Hidden mdDown>
				<StaticImage
					alt="Logoleiste mit Bundespräsident, Landtagen und Projektpartnern."
					layout="constrained"
					loading="eager"
					placeholder="blurred"
					quality={ 100 }
					src="../../assets/images/Jugend_debattiert_Logoleiste_quer_RGB.jpg" />
			</Hidden>
			<Hidden mdUp>
				<StaticImage
					alt="Logoleiste mit Bundespräsident, Landtagen und Projektpartnern."
					layout="constrained"
					loading="eager"
					placeholder="blurred"
					quality={ 100 }
					src="../../assets/images/Jugend_debattiert_Logoleiste_hoch_RGB.jpg" />
			</Hidden>
		</Box>
	)

}

export default FooterBanner
