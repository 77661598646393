/**
 * AppDrawerNavItem.jsx
 *
 * @file This component renders the navigation list items.
 * @author Robin Walter <hello@robinwalter.me>
 */

import Collapse from '@mui/material/Collapse'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import ListItemText from '@mui/material/ListItemText'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from '@reach/router'

// internal imports
import { Link } from '../Link'
import { toggleAppDrawer } from '../../state'

/**
 * Render the navigation items of the subroutes.
 *
 * @param {Object} routes – The subroutes to render.
 * @returns {Node} The rendered sublist.
 */
const renderAppDrawerNavItems = routes => {

	return routes.map( ( { path, subroutes, title }, i ) => (
		<List component="div" disablePadding key={ i }>
			<AppDrawerNavItem
				nested
				path={ path }
				subroutes={ subroutes }
				title={ title } />
		</List>
	) )

}

/**
 * Render the navigation list item.
 *
 * @param {Object} props – The component props.
 * @returns {Node} The rendered navigation item.
 */
const AppDrawerNavItem = ({ nested, subroutes, title, ...props }) => {

	const dispatch = useDispatch()

	/** Define a state to toggle the sublist. */
	const [ open, setOpen ] = useState( false )

	const path = props.path.split( ':' )[ 0 ]

	const location = useLocation()

	if ( subroutes )
		return (
			<>
				<ListItem dense sx={{ pl: nested && 4 }}>
					<ListItemText>
						<Link
							color="inherit"
							onClick={ () => { dispatch( toggleAppDrawer() ) } }
							sx={{
								color: path === location.pathname && 'primary.main',
								fontWeight: path === location.pathname && 'typography.fontWeightMedium',
							}}
							to={ path }>
							{ title }
						</Link>
					</ListItemText>
					<ListItemSecondaryAction>
						<IconButton
							aria-label={ open ? `Schließe Unternavigation` : `Öffne Unternavigation` }
							onClick={ () => { setOpen( !open ) } }
							size="small">
							{ open ? <ExpandLess /> : <ExpandMore /> }
						</IconButton>
					</ListItemSecondaryAction>
				</ListItem>
				<Collapse in={ open } timeout="auto" unmountOnExit>
					{ renderAppDrawerNavItems( subroutes ) }
				</Collapse>
			</>
		)

	return (
		<ListItem dense sx={{ pl: nested && 4 }}>
			<ListItemText>
				<Link
					color="inherit"
					onClick={ () => { dispatch( toggleAppDrawer() ) } }
					sx={{
						color: path === location.pathname && 'primary.main',
						fontWeight: path === location.pathname && 'typography.fontWeightMedium',
					}}
					to={ path }>
					{ title }
				</Link>
			</ListItemText>
		</ListItem>
	)
}

/**
 * Define the (expected) prop-types of this component.
 *
 * @typedef {Object} AppDrawerNavItem.propTypes
 * @property {boolean}  nested      – Indicates whether the component should have some left padding.
 * @property {string}   path        – The path of the route.
 * @property {Object}   [subroutes] – An object that holds the subroutes.
 * @property {string}   title       – The title of the route.
 */
AppDrawerNavItem.propTypes = {
	nested: PropTypes.bool,
	path: PropTypes.string.isRequired,
	subroutes: PropTypes.array,
	title: PropTypes.string.isRequired
}

export default AppDrawerNavItem
