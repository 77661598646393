/**
 * wrapPageElement.jsx
 *
 * @file This component is injected via Gatsby's Browser & SSR APIs,
 * to wrap the root element of this web application with all necessary
 * providers.
 * @author Robin Walter <hello@robinwalter.me>
 */

import React from 'react'

// internal imports
import { Layout } from './components/Layout'

// Pass all props (hence the ...props) to the layout component so it has access to things like pageContext or location
export default ( { element, props } ) => <Layout { ...props }>{ element }</Layout>
