/**
 * eventRegistrationSlice.js
 *
 * @file Define a reducer for the event registration form.
 * @author Robin Walter <hello@robinwalter.me>
 */

import { createSlice } from '@reduxjs/toolkit'

/** Define and export the initial or default registration form state. */
export const eventRegistrationInitialState = {
	acceptedPrivacyPolicy: false,
	appellation: {
		error: false,
		value: '',
		verified: true
	},
	city: {
		error: false,
		value: '',
		verified: false
	},
	event: {
		error: false,
		value: {
			eventID: '',
			id: '',
			label: 'Bitte wählen Sie eine Veranstaltung',
			venue: {
				city: '',
				id: '',
				__typename: 'Venue'
			},
			__typename: 'Event'
		},
		verified: false
	},
	events: [],
	firstName: {
		error: false,
		value: '',
		verified: false
	},
	firstParticipation: {
		error: false,
		value: '',
		verified: false
	},
	mail: {
		error: false,
		value: '',
		verified: false,
	},
	message: '',
	name: {
		error: false,
		value: '',
		verified: false
	},
	participants: [],
	postalCode: {
		error: false,
		value: '',
		verified: false
	},
	regionalNetwork: {
		error: false,
		value: '',
		verified: false
	},
	school: {
		error: false,
		value: {
			city: '',
			firstParticipation: '',
			id: '0',
			key: '0',
			label: 'Bitte wählen Sie eine Schule',
			name: '',
			postalCode: '',
			regionalNetwork: '',
			__typename: 'School'
		},
		verified: false
	},
	schools: [],
	type: ''
}

/** Create and export the reducer slice for the `eventRegistration` key. */
export const eventRegistrationSlice = createSlice( {
	initialState: eventRegistrationInitialState,
	name: 'eventRegistration',
	reducers: {
		/**
		 * Add a new element to the end of the `participants`.
		 *
		 * @param {Object} state The current state of the `eventRegistration` key.
		 * @param {Object} action The action object with the payload, which holds the new state.
		 */
		addParticipant: ( state, action ) => {
			state.participants.push( action.payload )
		},
		/**
		 * Change the accepted privacy policy.
		 *
		 * @param {Object} state The current state of the `eventRegistration` key.
		 * @param {Object} action The action object with the payload, which holds the new state.
		 */
		changeAcceptedPrivacyPolicy: ( state, action ) => {
			state.acceptedPrivacyPolicy = action.payload
		},
		/**
		 * Change the appellation.
		 *
		 * @param {Object} state The current state of the `eventRegistration` key.
		 * @param {Object} action The action object with the payload, which holds the new state.
		 */
		changeAppellation: ( state, action ) => {
			state.appellation = action.payload
		},
		/**
		 * Change the city.
		 *
		 * @param {Object} state The current state of the `eventRegistration` key.
		 * @param {Object} action The action object with the payload, which holds the new state.
		 */
		changeCity: ( state, action ) => {
			state.city = action.payload
		},
		/**
		 * Change the event.
		 *
		 * @param {Object} state The current state of the `eventRegistration` key.
		 * @param {Object} action The action object with the payload, which holds the new state.
		 */
		changeEvent: ( state, action ) => {
			state.event = action.payload
		},
		/**
		 * Change the first name.
		 *
		 * @param {Object} state The current state of the `eventRegistration` key.
		 * @param {Object} action The action object with the payload, which holds the new state.
		 */
		changeFirstName: ( state, action ) => {
			state.firstName = action.payload
		},
		/**
		 * Change the first participation.
		 *
		 * @param {Object} state The current state of the `eventRegistration` key.
		 * @param {Object} action The action object with the payload, which holds the new state.
		 */
		changeFirstParticipation: ( state, action ) => {
			state.firstParticipation = action.payload
		},
		/**
		 * Change the e-mail address.
		 *
		 * @param {Object} state The current state of the `eventRegistration` key.
		 * @param {Object} action The action object with the payload, which holds the new state.
		 */
		changeMail: ( state, action ) => {
			state.mail = action.payload
		},
		/**
		 * Change the message.
		 *
		 * @param {Object} state The current state of the `eventRegistration` key.
		 * @param {Object} action The action object with the payload, which holds the new state.
		 */
		changeMessage: ( state, action ) => {
			state.message = action.payload
		},
		/**
		 * Change the family name.
		 *
		 * @param {Object} state The current state of the `eventRegistration` key.
		 * @param {Object} action The action object with the payload, which holds the new state.
		 */
		changeName: ( state, action ) => {
			state.name = action.payload
		},
		/**
		 * Change a specific `Participant` with a given index.
		 *
		 * @param {Object} state The current state of the `eventRegistration` key.
		 * @param {Object} action The action object with the payload, which holds the new state.
		 * @returns {any} Fails if no index or participant was provided.
		 */
		changeParticipant: ( state, action ) => {
			if ( typeof action.index === 'undefined' || typeof action.participant === 'undefined' ) return
			state.participants[ action.index ] = action.participant
		},
		/**
		 * Change the whole `participants` array.
		 *
		 * @param {Object} state The current state of the `eventRegistration` key.
		 * @param {Object} action The action object with the payload, which holds the new state.
		 */
		changeParticipants: ( state, action ) => {
			state.participants = action.payload
		},
		/**
		 * Change the postal code.
		 *
		 * @param {Object} state The current state of the `eventRegistration` key.
		 * @param {Object} action The action object with the payload, which holds the new state.
		 */
		changePostalCode: ( state, action ) => {
			state.postalCode = action.payload
		},
		/**
		 * Change the regional network.
		 *
		 * @param {Object} state The current state of the `eventRegistration` key.
		 * @param {Object} action The action object with the payload, which holds the new state.
		 */
		changeRegionalNetwork: ( state, action ) => {
			state.regionalNetwork = action.payload
		},
		/**
		 * Change the school.
		 *
		 * @param {Object} state The current state of the `eventRegistration` key.
		 * @param {Object} action The action object with the payload, which holds the new state.
		 */
		changeSchool: ( state, action ) => {
			state.school = action.payload
		},
		/**
		 * Remove the last element from the `participants`.
		 *
		 * @param {Object} state The current state of the `eventRegistration` key.
		 */
		popParticipant: state => {
			state.participants.pop()
		},
		/**
		 * Resets the state to its initial values. Optionally sets the *new* event type.
		 *
		 * @param {Object} _ The current state of the `eventRegistration` key.
		 * @param {Object} action The action object with the payload, which holds the new state.
		 * @returns {any} Returns the initial state as the new state. Maybe modified with an event type.
		 * Fails if the given event type is unknown.
		 */
		resetEventRegistration: ( _, action ) => {
			if ( action.payload ) {
				return {
					...eventRegistrationInitialState,
					type: action.payload
				}
			}
			return eventRegistrationInitialState
		},
		/**
		 * Sets the event type. The event type is used to render the form correctly
		 * and to decide if the state should be reset.
		 *
		 * @param {Object} state The current state of the `eventRegistration` key.
		 * @param {Object} action The action object with the payload, which holds the new state.
		 * @returns {any} Fails if the given event type is unknown.
		 */
		setType: ( state, action ) => {
			state.type = action.payload
		},
		/**
		 * Remove the first element from the `participants`.
		 *
		 * @param {Object} state The current state of the `eventRegistration` key.
		 */
		shiftParticipant: state => {
			state.participants.shift()
		},
		/**
		 * Remove an element with a given index (key `start`) from the `participants`. It's even
		 * possible to remove a range of elements. To remove a range of elements from the
		 * `participants` provide an index (key `start`) and number of total elements to remove
		 * (key `total`). **NOTE**: The second key `total` is optional.
		 *
		 * @param {Object} state The current state of the `eventRegistration` key.
		 * @param {Object} action The action object with the payload, which holds the new state.
		 * @returns {any} Fails if the key `start` is `undefined`.
		 */
		spliceParticipants: ( state, action ) => {
			if ( typeof action.payload.start === 'undefined' ) return
			state.participants.splice( action.payload.start, action.payload.total || action.payload.start )
		},
		/**
		 * Store the events fetched from the GraphQL Server.
		 *
		 * @param {Object} state The current state of the `eventRegistration` key.
		 * @param {Object} action The action object with the payload, which holds the new state.
		 */
		storeEvents: ( state, action ) => {
			state.events = action.payload
		},
		/**
		 * Store the schools fetched from the GraphQL Server.
		 *
		 * @param {Object} state The current state of the `eventRegistration` key.
		 * @param {Object} action The action object with the payload, which holds the new state.
		 */
		storeSchools: ( state, action ) => {
			state.schools = action.payload
		},
		/**
		 * Update the whole event registration at once.
		 *
		 * @param {Object} state The current state of the `eventRegistration` key.
		 * @param {Object} action The action object with the payload, which holds the new state.
		 */
		updateRegistration: ( state, action ) => {
			return {
				...state,
				...action.payload
			}
		}
	}
} )

/** Export the created action functions. */
export const {
	addParticipant,
	changeAcceptedPrivacyPolicy,
	changeAppellation,
	changeCity,
	changeEvent,
	changeFirstName,
	changeFirstParticipation,
	changeMail,
	changeMessage,
	changeName,
	changeParticipant,
	changeParticipants,
	changePostalCode,
	changeRegionalNetwork,
	changeSchool,
	popParticipant,
	resetEventRegistration,
	setType,
	shiftParticipant,
	spliceParticipants,
	storeEvents,
	storeSchools,
	updateRegistration
} = eventRegistrationSlice.actions

/** Export the selector for the `acceptedPrivacyPolicy` key. */
export const selectEventRegistrationAcceptedPrivacyPolicy = state => state.eventRegistration.acceptedPrivacyPolicy
/** Export the selector for the `appellation` key. */
export const selectEventRegistrationAppellation = state => state.eventRegistration.appellation
/** Export the selector for the `city` key. */
export const selectEventRegistrationCity = state => state.eventRegistration.city
/** Export the selector for the `event` key. */
export const selectEventRegistrationEvent = state => state.eventRegistration.event
/** Export the selector for the `events` key. */
export const selectEventRegistrationEvents = state => state.eventRegistration.events
/** Export the selector for the `firstName` key. */
export const selectEventRegistrationFirstName = state => state.eventRegistration.firstName
/** Export the selector for the `firstParticipation` key. */
export const selectEventRegistrationFirstParticipation = state => state.eventRegistration.firstParticipation
/** Export the selector for the `mail` key. */
export const selectEventRegistrationMail = state => state.eventRegistration.mail
/** Export the selector for the `message` key. */
export const selectEventRegistrationMessage = state => state.eventRegistration.message
/** Export the selector for the `name` key. */
export const selectEventRegistrationName = state => state.eventRegistration.name
/** Export the selector for the `participants` key. */
export const selectEventRegistrationParticipants = state => state.eventRegistration.participants
/** Export the selector for the `postalCode` key. */
export const selectEventRegistrationPostalCode = state => state.eventRegistration.postalCode
/** Export the selector for the `regionalNetwork` key. */
export const selectEventRegistrationRegionalNetwork = state => state.eventRegistration.regionalNetwork
/** Export the selector for the `school` key. */
export const selectEventRegistrationSchool = state => state.eventRegistration.school
/** Export the selector for the `schools` key. */
export const selectEventRegistrationSchools = state => state.eventRegistration.schools
/** Export the selector for the `type` key. */
export const selectEventRegistrationType = state => state.eventRegistration.type

/** Export the created reducer function. */
export default eventRegistrationSlice.reducer
