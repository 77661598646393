/**
 * settingsSlice.js
 *
 * @file Define a reducer for the user settings feature.
 * @author Robin Walter <hello@robinwalter.me>
 */

import { createSlice } from '@reduxjs/toolkit'
import isIn from 'validator/es/lib/isIn'
import logging from 'loglevel'

const logger = logging.getLogger('redux')

export const settingsInitialState = {
	appellation: '',
	firstName: '',
	fontSize: 16,
	mail: '',
	name: '',
	school: {
		city: '',
		firstParticipation: '',
		id: '0',
		key: '0',
		label: 'Bitte wählen Sie eine Schule',
		name: '',
		postalCode: '',
		regionalNetwork: '',
		__typename: 'School'
	},
	uiMode: 'system',
}

/** Create and export the slice for the app drawer. */
export const settingsSlice = createSlice( {
	initialState: settingsInitialState,
	name: 'settings',
	reducers: {
		/**
		 * Adjust the font size to a custom value.
		 *
		 * @param {Object} state The current state of the font size.
		 * @returns {Object} The new state of the font size.
		 */
		adjustFontSize: (state, action) => {
			if (typeof action.payload.size === 'undefined' || typeof action.payload.size !== 'number') {
				logger.warn('settings.adjustFontSize was called w/ wrong payload')
				return
			}
			else if ( action.payload.size < 12 || action.payload.size > 20 ) {
				logger.warn('settings.adjustFontSize was called with number out of range')
				return
			}
			state.fontSize = action.payload.size
		},
		/**
		 * Change the appellation.
		 *
		 * @param {Object} state The current state of the `settings` key.
		 * @param {Object} action The action object with the payload, which holds the new state.
		 */
		changeAppellation: ( state, action ) => {
			state.appellation = action.payload
		},
		/**
		 * Change the first name.
		 *
		 * @param {Object} state The current state of the `settings` key.
		 * @param {Object} action The action object with the payload, which holds the new state.
		 */
		changeFirstName: ( state, action ) => {
			state.firstName = action.payload
		},
		/**
		 * Change the e-mail address.
		 *
		 * @param {Object} state The current state of the `settings` key.
		 * @param {Object} action The action object with the payload, which holds the new state.
		 */
		changeMail: ( state, action ) => {
			state.mail = action.payload
		},
		/**
		 * Change the family name.
		 *
		 * @param {Object} state The current state of the `settings` key.
		 * @param {Object} action The action object with the payload, which holds the new state.
		 */
		changeName: ( state, action ) => {
			state.name = action.payload
		},
		/**
		 * Change the school.
		 *
		 * @param {Object} state The current state of the `settings` key.
		 * @param {Object} action The action object with the payload, which holds the new state.
		 */
		changeSchool: ( state, action ) => {
			state.school = action.payload
		},
		/**
		 * Switch the current state of the color mode.
		 *
		 * @param {Object} state The current state of the color mode.
		 * @returns {Object} The new state of the color mode.
		 */
		switchUIMode: (state, action) => {
			if (typeof action.payload.mode === 'undefined') {
				logger.warn('settings.switchUIMode was called without a payload')
				return
			}
			else if ( !isIn( action.payload.mode, ['system', 'light', 'dark'] ) ) {
				logger.warn('settings.switchUIMode was called with unknown option. Ignoring the input')
				return
			}
			state.uiMode = action.payload.mode
		},
	},
} )

/** Export the created action functions. */
export const {
	adjustFontSize,
	changeAppellation,
	changeFirstName,
	changeMail,
	changeName,
	changeSchool,
	switchUIMode,
} = settingsSlice.actions

/** Export the selector for the `appellation` key. */
export const selectSettingAppellation = state => state.settings.appellation

/** Export the selector for the `firstName` key. */
export const selectSettingFirstName = state => state.settings.firstName

/** Export the selector to receive the current state of the font size setting. */
export const selectSettingFontSize = state => state.settings.fontSize

/** Export the selector for the `mail` key. */
export const selectSettingMail = state => state.settings.mail

/** Export the selector for the `name` key. */
export const selectSettingName = state => state.settings.name

/** Export the selector for the `school` key. */
export const selectSettingSchool = state => state.settings.school

/** Export the selector to receive the current state of the color mode setting. */
export const selectSettingUIMode = state => state.settings.uiMode

export default settingsSlice.reducer
