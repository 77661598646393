module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://jugend-debattiert-nrw.de","stripQueryString":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Nunito:400,400i,600,600i"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"description":"Jugend debattiert ist ein Wettbewerb für Schülerinnen und Schüler, der auf Initiative und unter Schirmherrschaft des Bundespräsidenten von der Gemeinnützigen Hertie-Stiftung, der Robert Bosch Stiftung, der Stiftung Mercator und der Heinz Nixdorf Stiftung in Kooperation mit den Kultusministerien, der Kultusministerkonferenz und den Parlamenten der Länder durchgeführt wird.","language":"de","metaTags":[{"content":"v2021.2.0","property":"version"}],"openGraph":{"locale":"de_DE","site_name":"Jugend debattiert NRW","type":"website","url":"https://jugend-debattiert-nrw.de"},"titleTemplate":"%s | Jugend debattiert NRW","twitter":{"cardType":"summary"}},
    },{
      plugin: require('../../gatsby-plugin-jd-server/gatsby-browser.js'),
      options: {"plugins":[],"clientName":"jd-webapp","clientVersion":"2021.2.0","coordinators":[{"id":1,"name":"Kerstin Hauke","email":"hauke@jugend-debattiert-nrw.de","profileUrl":"Hauke.jpeg","title":"Landeskoordinationsteam","text":["› Ansprechpartner für interessierte Schulen bzw. Lehrkräfte","› Organisation des Wettbewerbs auf Landesebene (Qualifikation und Finale)","› Öffentlichkeitsarbeit"]},{"id":2,"name":"Stephan Lange-Weber","email":"stephan.lange-weber@jugend-debattiert-nrw.de","profileUrl":"Lange-Weber.jpeg","title":"Landeskoordinationsteam","text":["› Ansprechpartner für interessierte Schulen bzw. Lehrkräfte","› Organisation des Wettbewerbs auf Landesebene (Qualifikation und Finale)","› Öffentlichkeitsarbeit"]},{"id":3,"name":"Sebastian Berg","email":"berg@jugend-debattiert-nrw.de","profileUrl":"Berg.jpeg","title":"stellvertretender Landeskoordinator / Fortbildungsbeauftragter","text":["› Koordination der Veranstaltungen und Schulungen","› Betreuung der Webseite und des Online-Kalenders","› Ansprechpartner für Kooperationen"]}],"profilesUrl":"https://files.jugend-debattiert-nrw.de/storage/images/avatars/","url":"https://api.jugend-debattiert-nrw.de/graphql"},
    },{
      plugin: require('../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#FFFFFF","description":"Jugend debattiert ist ein Wettbewerb für Schülerinnen und Schüler, der auf Initiative und unter Schirmherrschaft des Bundespräsidenten von der Gemeinnützigen Hertie-Stiftung, der Robert Bosch Stiftung, der Stiftung Mercator und der Heinz Nixdorf Stiftung in Kooperation mit den Kultusministerien, der Kultusministerkonferenz und den Parlamenten der Länder durchgeführt wird.","display":"standalone","icon":"src/assets/images/icon-512x512.png","lang":"de","name":"Jugend debattiert NRW","short_name":"JD NRW","start_url":"/","theme_color":"#009EE3","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"788b607dc495905fa055b0867708f9a1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"cookieDomain":".jugend-debattiert-nrw.de","dev":false,"disableCookies":false,"exclude":["/offline-plugin-app-shell-fallback/","404"],"localScript":null,"matomoJsScript":"matomo.js","matomoPhpScript":"matomo.php","matomoUrl":"https://stats.jugend-debattiert-nrw.de","requireConsent":true,"siteId":"1","siteUrl":"https://jugend-debattiert-nrw.de","trackLoad":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/index.html","/ansprechpartner","/datenschutz","/impressum","/terminkalender"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
