/**
 * wrapWithProviders.jsx
 *
 * @file This component is injected via Gatsby's Browser & SSR APIs,
 * to wrap the root element of this web application with all necessary
 * providers.
 * @author Robin Walter <hello@robinwalter.me>
 */

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { ApolloProvider } from '@apollo/client'
import {
	createTheme,
	responsiveFontSizes,
	StyledEngineProvider,
	ThemeProvider
} from '@mui/material/styles'
import { deDE } from '@mui/material/locale'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider as ReduxProvider } from 'react-redux'
import React, { useMemo } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useSelector } from 'react-redux'

// internal imports
import { ApolloClient } from './Context'
import {
	darkPalette,
	lightPalette,
	darkNprogress,
	lightNprogress,
	typography
} from './styles'
import {
	persistor,
	selectSettingFontSize,
	selectSettingUIMode,
	store
} from './state'

const WithCustomizableThemeProvider = ({ element }) => {
	const prefersDarkMode = useMediaQuery( '( prefers-color-scheme: dark )' )

	/** Receive the font size state from the store. */
	const fontSize = useSelector( selectSettingFontSize )
	/** Receive the font size state from the store. */
	const uiMode = useSelector( selectSettingUIMode )

	let useDarkMode = null

	if ( uiMode === 'light' )
		useDarkMode = false
	else if ( uiMode === 'dark' )
		useDarkMode = true
	else
		useDarkMode = prefersDarkMode

	const theme = useMemo(
		() => responsiveFontSizes(
			createTheme({
				nprogress: useDarkMode ? darkNprogress : lightNprogress,
				palette: useDarkMode ? darkPalette : lightPalette,
				shape: {
					borderRadius: 4,
				},
				spacing: 8,
				typography: {
					...typography,
					fontSize: fontSize
				},
				components: {
					MuiLink: {
						defaultProps: {
							underline: 'hover',
						},
					},
					MuiTextField: {
						defaultProps: {
							variant: 'standard',
						},
					},
				},
			}, deDE),
			{
				breakpoints: [ 'sm', 'md', 'lg' ],
				disableAlign: false,
				factor: 2,
				variants: [
					'body1',
					'body2',
					'button',
					'caption',
					'h1',
					'h2',
					'h3',
					'h4',
					'h5',
					'h6',
					'overline',
					'subtitle1',
					'subtitle2',
				],
			},
		),
		[ fontSize, useDarkMode ]
	)

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={ theme }>
				<LocalizationProvider dateAdapter={ AdapterMoment }>
					{ element }
				</LocalizationProvider>
			</ThemeProvider>
		</StyledEngineProvider>
	)
}

const WithProviders = ({ element }) => {

	return (
        <ReduxProvider store={ store }>
			<PersistGate loading={ null } persistor={ persistor }>
				<ApolloProvider client={ ApolloClient }>
					<WithCustomizableThemeProvider element={ element } />
				</ApolloProvider>
			</PersistGate>
		</ReduxProvider>
    )
}

/** Wrap all content with the necessary context providers. */
export default ({ element }) => {

	return (
		<WithProviders element={ element } />
	)

}
